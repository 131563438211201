(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller:FileBulkErrorDialogCtrl
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .controller('FileBulkErrorDialogCtrl', FileBulkErrorDialogCtrl);

  function FileBulkErrorDialogCtrl($mdDialog) {
    var vm = this;

    vm.closeDialog = function closeDialog() {
      $mdDialog.hide();
    };
  }
}());
